<script lang="ts" setup>

    const error = useError();

    const handleError = () => {
        clearError({
            redirect: '/',
        });
    };

</script>

<template>
    
        <main class="container max-w-screen-narrow flex flex-col gap-4 justify-center items-center text-center py-6 md:py-8 lg:py-10 my-auto min-h-screen">
            <img 
                src="~/assets/renewabl_404-flat.svg" 
                alt="404"
                width="1000"
                height="400"
                class="w-full h-auto"
                loading="eager"
            >
            <span class="font-hass-display text-d2_mobile md:text-d2_tablet lg:text-d2_desktop text-lima-600 font-medium">Oops!!</span>
            <span class="font-hass-display text-h6_mobile md:text-h6_tablet lg:text-h6_desktop text-elephant-950 font-medium">We’re really sorry but this page doesn’t exist or is unavailable right now.<br/>That’s our fault, not yours.</span>
            <button @click.prevent="handleError()" title="Home" aria-label="Home" class="button button--fill" :style="`--theme: #00755f;`">Return Home</button>
        </main>
 
</template>


<style lang="scss">

    .button {
        @apply 
        
        transition-all ease-in-out duration-100 
        
        flex flex-col justify-center items-center 
        
        font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-center 

        bg-[var(--theme)] [&.button--ghost]:bg-[transparent]

        hover:bg-[transparent] [&.button--ghost]:hover:bg-[var(--theme)]

      text-white-50 [&.button--ghost]:text-[var(--theme)]

        hover:text-[var(--theme)] [&.button--ghost]:hover:text-[#ffffff] 

        border-[2px] border-[var(--theme)]

        h-5 md:h-6 w-auto px-4 rounded-full
    }

</style>